<template>
  <div>
    <b-card>
      <div class="row mb-4">
        <div class="col-12">
          <modal-actions @close="$router.push({ name: 'actors' })" />
        </div>
      </div>
      <div class="shdow">
        

        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <p class="h2 text-secondary">Modifier bénéficiaire</p>
          <b-button
            variant="secondary"
            >Confirmer</b-button
          >
          
          <hr class="w-100" />
        </div>

        
        <div class="row">
          <!-- First Col -->
          <div class="col-12 col-md-4">
            <b-form-group
              label-cols="12"
              label="Bénéficiaire"
              label-class="font-weight-bold"
            >
              <b-form-select :options="['Clients', 'Partenaires', 'Fornisseurs']"></b-form-select>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Type"
              label-class="font-weight-bold"
            >
              <b-form-select :options="['Externe', 'Interne']"></b-form-select>
            </b-form-group>

          </div>
        </div>
      </div>

      
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
export default {
  components: {
    ModalActions,
  },
  data: () => ({
    editMode: false,
    affectedTeams: {
      tableItems: [
        {
          team_label: "Lorem Ipsum",
          responsable: "Leslie Bechtelar",
          organisation_attachment: "Cadrage",
          collaborators_number: "4",
          actions: ["edit"],
        },
        {
          team_label: "Guillermo Nienow",
          responsable: "Ms. Melissa Fay",
          organisation_attachment: "Cadrage",
          collaborators_number: "6",
          actions: ["edit"],
        },
      ],
      tableFields: [
        { key: "team_label", label: "Libellé équipe", sortable: true },
        { key: "responsable", label: "Responsable", sortable: true },
        {
          key: "organisation_attachment",
          label: "Organisation de rattachement",
          sortable: true,
        },
        {
          key: "collaborators_number",
          label: "Nombre de collaborateurs",
          sortable: true,
        },
        { key: "actions", label: "" },
      ],
    },
  }),
  methods: {
    
  },
};
</script>

<style></style>
